import "./App.css";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
  useOrganizationList,
  OrganizationProfile,
  OrganizationSwitcher,
} from "@clerk/clerk-react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Admin } from "@quillsql/admin";

const CONTAINER = {
  display: "flex",

  flexDirection: "row",

  height: "800px",

  background: "white",

  marginTop: 32,

  width: "calc(100% - 50px)",

  // borderRadius: 12,

  overflow: "hidden",

  borderRadius: 12,

  // border: "1px solid #EDEDED",

  boxShadow: "0px 1px 24px 0px rgba(0, 0, 0, 0.09)",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    transform: "translate(-50%, -50%)",
    borderRadius: 6,
    zIndex: 4,
    boxShadow: "0px 2px 24px 0px rgb(63,82,149, 0.09)",
  },
};

const THEME4 = {
  /* Components font family */

  // fontFamily: 'BlinkMacSystemFont',

  fontFamily: "Inter",

  /* Default font size */

  fontSize: 14,

  /* Header font size */

  headerFontSize: 18,

  editorDarkMode: true,

  /* Primary color */

  primaryColor: "#212121",

  buttonUnderlayColor: "#000000",

  /* Secondary color */

  secondaryColor: "white",

  selectedTabColor: "white",

  /* When you select underlay */

  selectUnderlayColor: "#F5F5F5",

  /* Background color */

  backgroundColor: "#FFFFFF",

  editorBackgroundColor: "#FBFBFB",

  elevatedCardColor: "#FFFFFF",

  /* Primary font color */

  fontColor: "#212121",

  /* Secondary font color */

  secondaryFontColor: "#777777",

  selectedFontColor: "#212121",

  unselectedFontColor: "#777777",

  buttonLabelColor: "white",

  buttonFontWeight: "500",

  /* Label font weight */

  labelFontWeight: "500",

  /* Header font weight */

  headerFontWeight: "500",

  /* Selected font weight */

  selectedFontWeight: "700",

  /* Padding for containers */

  padding: 25,

  /* Border radius for selected Quill elements */

  borderRadius: "8px",

  /* Box shadow applied on Quill components */

  boxShadow: "0px 1px 8px 0px rgb(63,82,149, 0.09)",

  shadowColor: "rgba(0, 0, 0, 0.09)",
};

const frontendApi = "clerk.withquill.com";
// const frontendApi = "clerk.romantic.quetzal-89.lcl.dev";

const theme = {
  fontFamily: "Inter",
  backgroundColor: "#FFFFFF",
  primaryTextColor: "#364153",
  secondaryTextColor: "#6C727F",
  chartLabelFontFamily: "Avenir",
  chartLabelColor: "#666666",
  chartTickColor: "#CCCCCC",
  chartColors: ["#6269E9", "#E14F62"],
  borderColor: "#E5E7EB",
  borderWidth: 1,
  primaryButtonColor: "#212121",
  buttonFontWeight: 500,
};

function App2() {
  const [organizationId, setOrganizationId] = useState("2");
  const organizationOptions = [
    { label: "Acme", value: "2" },
    { label: "Rocket Industries", value: "3" },
  ];
  return (
    <ClerkProvider
      localization={{
        organizationSwitcher: {
          action__manageOrganization: "Manage team",
          action__createOrganization: " ",
        },
      }}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
      frontendApi={frontendApi}
    >
      <SignedIn>
        <AdminWrapper />
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  );
}

function AdminWrapper() {
  const { user } = useUser();
  const { setActive, organizationList, isLoaded } = useOrganizationList();
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [environment, setEnvironment] = useState("PRODUCTION");
  const [organizations, setOrganizations] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    async function getClient() {
      const response1 = await axios.get(
        `https://quill-344421.uc.r.appspot.com/user_client/${user.emailAddresses[0].emailAddress}`,
        { headers: { Authorization: `Bearer ` } }
      );

      setClients(response1.data.clients);
      setClient(response1.data.clients[0]);
    }
    if (isSubscribed) {
      getClient();
    }
    return () => {
      isSubscribed = false;
    };
  }, [user]);

  useEffect(() => {
    let isSubscribed = true;
    async function getCustomers() {
      const response = await axios.get(
        `https://quill-344421.uc.r.appspot.com/orgs/${client._id}/`,
        {
          headers: environment
            ? { Authorization: `Bearer `, environment }
            : { Authorization: `Bearer ` },
        }
      );

      setOrganizations([
        ...response.data.orgs,
        { id: "", name: "All Organizations" },
      ]);

      if (environment === "STAGING") {
        setOrganizationId(client.stagingAdminCustomerId);
      } else {
        setOrganizationId(client.adminCustomerId);
      }
    }
    if (isSubscribed && client) {
      getCustomers();
    }
    return () => {
      isSubscribed = false;
    };
  }, [environment, client]);

  useEffect(() => {
    if (isLoaded && organizationList.length) {
      setActive({ organization: organizationList[0].organization.id });
    }
  }, [isLoaded]);

  if (!client) {
    return null;
  }

  return (
    <Admin
      theme={theme}
      // publicKey='64db9a2bd2cc4f1d4c149046'
      // organizationId='064fb34d-b287-7d47-8000-a5f6f065c2d9'
      organizationId={organizationId}
      publicKey={client._id}
      environment={environment}
      EnvironmentSelectComponent={() => {
        if (client && client._id === "642ee580e45ecb91213b44aa") {
          return (
            <div
              style={{
                background: "#f7f7f7",
                borderRadius: 6,
                height: 38,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              <button
                style={{
                  background:
                    environment === "PRODUCTION" ? "white" : undefined,
                  color: environment === "PRODUCTION" ? "#384151" : "#677389",
                  fontWeight: environment === "PRODUCTION" ? "600" : "500",
                  borderRadius: 4,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 3,
                  paddingBottom: 3,
                  fontSize: 16,
                }}
                onClick={() => setEnvironment("PRODUCTION")}
              >
                Production
              </button>
              <button
                style={{
                  background: environment === "STAGING" ? "white" : undefined,
                  color: environment === "STAGING" ? "#384151" : "#677389",
                  fontWeight: environment === "STAGING" ? "600" : "500",
                  borderRadius: 4,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 3,
                  paddingBottom: 3,
                  fontSize: 16,
                }}
                onClick={() => setEnvironment("STAGING")}
              >
                Staging
              </button>
            </div>
          );
        }
        return (
          <EnvSelect
            client={client}
            clients={clients}
            setClient={setClient}
            theme={theme}
          />
        );
      }}
      OrganizationSelectComponent={() => (
        <OrgSelect
          environment={environment}
          setEnvironment={setEnvironment}
          organizations={organizations}
          theme={theme}
          organizationId={organizationId}
          setOrganizationId={setOrganizationId}
        />
      )}
      UserManagementComponent={() => {
        return (
          <div className="flex flex-row min-h-[50px] justify-end items-center pr-[8px]">
            {isLoaded && organizationList.length > 0 && (
              <OrganizationSwitcher
                hidePersonal
                appearance={{
                  variables: { fontFamily: "Inter" },
                  elements: {
                    organizationSwitcherPopoverCard: "rounded-lg pb-[12px]",
                    organizationSwitcherTrigger: "mt-[6px]",
                    organizationSwitcherPopoverActionButton__createOrganization:
                      "hidden",
                    organizationSwitcherPopoverActionButtonIconBox: "hidden",
                    organizationSwitcherPopoverActions: "hidden",
                  },
                }}
              />
            )}
            <div style={{ width: 6 }} />
            <div className="flex flex-col">
              <UserButton
                appearance={{
                  variables: { fontFamily: "Inter" },
                  elements: {
                    userButtonPopoverCard: "rounded-lg pt-[18px] pb-[10px]",
                  },
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

function EnvSelect({ clients, setClient, client, theme }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1
        style={{
          fontSize: "14px",
          paddingTop: "0px",
          marginTop: "0px",
          marginBottom: "4px",
          fontWeight: "600",
          color: theme.secondaryTextColor,
        }}
      >
        Environment
      </h1>
      <div style={{ position: "relative" }}>
        <select
          style={{
            width: "100%",
            minWidth: 230,
            maxWidth: 230,
            outline: "none",
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: 6,
            WebkitAppearance: "none",
            paddingLeft: 12,
            paddingRight: 12,
            height: 38,
            borderWidth: theme.borderWidth,
            borderColor: theme.borderColor,
            background: theme.backgroundColor,
            color: theme.primaryTextColor,
            boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
            fontFamily: theme.fontFamily,
          }}
          value={client._id}
          onChange={(e) =>
            setClient(clients.find((elem) => elem._id === e.target.value))
          }
        >
          {clients.map((option) => (
            <option key={option._id} value={option._id} label={option.name} />
          ))}
        </select>
        <svg
          style={{
            height: "20px",
            width: "20px",
            flex: "none",
            position: "absolute",
            right: 8,
            top: 9,
            color: theme?.secondaryTextColor,
          }}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="currentColor"
            d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
          />
        </svg>
      </div>
    </div>
  );
}

function OrgSelect({
  environment,
  setEnvironment,
  organizations,
  theme,
  organizationId,
  setOrganizationId,
}) {
  useEffect(() => {
    setEnvironment(environment);
  }, [environment]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h1
        style={{
          fontSize: "14px",
          paddingTop: "0px",
          marginTop: "0px",
          marginBottom: "4px",
          fontWeight: "600",
          color: theme.secondaryTextColor,
        }}
      >
        Organization
      </h1>
      <div style={{ position: "relative" }}>
        <select
          style={{
            width: "100%",
            minWidth: 230,
            maxWidth: 230,
            outline: "none",
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: 6,
            WebkitAppearance: "none",
            paddingLeft: 12,
            paddingRight: 12,
            height: 38,
            borderWidth: theme.borderWidth,
            borderColor: theme.borderColor,
            background: theme.backgroundColor,
            color: theme.primaryTextColor,
            boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
            fontFamily: theme.fontFamily,
          }}
          value={organizationId}
          onChange={(e) => setOrganizationId(e.target.value)}
        >
          {organizations.map((option) => (
            <option key={option.id} value={option.id} label={option.name} />
          ))}
        </select>
        <svg
          style={{
            height: "20px",
            width: "20px",
            flex: "none",
            position: "absolute",
            right: 8,
            top: 9,
            color: theme?.secondaryTextColor,
          }}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="currentColor"
            d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"
          />
        </svg>
      </div>
    </div>
  );
}

export default App2;
